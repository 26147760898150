/**
 *  (c) 智汇考题 
 */

import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Index",
        meta: {
          title: "首页",
          keepAlive: true,
          useCache:false
        },
        component: () => import("../views/index/index.vue"),
    },
    {
        path: "/topic",
        name: "Topic",
        meta: {
          title: "资讯",
          keepAlive: true,
          useCache:false
        },
        component: () => import("../views/topic/index.vue"),
    },
    {
        path: "/topic/detail",
        name: "TopicDetail",
        meta: {
          title: "资讯详情",
        },
        component: () => import("../views/topic/detail.vue"),
    },
    {
        path: "/user",
        name: "User",
        meta: {
          title: "个人中心",
        },
        component: () => import("../views/user/index.vue"),
    },
    {
        path: "/user/info",
        name: "UserInfo",
        meta: {
          title: "个人信息",
          auth: true,
        },
        component: () => import("../views/user/info.vue"),
    },
    {
      path: "/user/protocol",
      name: "Protocol",
      meta: {
        title: "协议",
      },
      component: () => import("../views/user/protocol.vue"),
    },
    {
      path: "/user/exam",
      name: "UserExam",
      meta: {
        title: "我的考试",
        auth: true,
      },
      component: () => import("../views/user/exam.vue"),
    },
    {
      path: "/user/exam/record",
      name: "UserExamRecord",
      meta: {
        title: "考试记录",
        auth: true,
      },
      component: () => import("../views/user/exam-record.vue"),
    },
    {
      path: "/user/mock",
      name: "UserMock",
      meta: {
        title: "我的模考",
        auth: true,
      },
      component: () => import("../views/user/mock.vue"),
    },
    {
      path: "/user/mock/record",
      name: "UserMockRecord",
      meta: {
        title: "模拟考试记录",
        auth: true,
      },
      component: () => import("../views/user/mock-record.vue"),
    },
    {
      path: "/user/learn",
      name: "UserLearn",
      meta: {
        title: "我的练习",
        auth: true,
      },
      component: () => import("../views/user/learn.vue"),
    },
    {
        path: "/user/password",
        name: "ChangePassword",
        meta: {
          title: "重置密码",
          auth: true,
        },
        component: () => import("../views/user/password.vue"),
    },
    {
        path: "/login",
        name: "Login",
        meta: {
          title: "登录",
        },
        component: () => import("../views/auth/login.vue"),
    },
    {
      path: "/register",
      name: "Register",
      meta: {
        title: "注册",
      },
      component: () => import("../views/auth/register.vue"),
  },
    {
        path: "/answer/exams",
        name: "AnswerExams",
        meta: {
          title: "在线考试",
          auth: true,
          keepAlive: true,
          useCache:false
        },
        component: () => import("../views/answer/exam/index.vue"),
    },
    {
      path: "/answer/exams/detail",
      name: "AnswerExamsDetail",
      meta: {
        title: "考试详情",
        auth: true,
      },
      component: () => import("../views/answer/exam/detail.vue"),
    },
    {
      path: "/answer/exams/play",
      name: "AnswerExamsPlay",
      meta: {
        auth: true,
      },
      component: () => import("../views/answer/exam/play.vue"),
    },
    {
      path: "/answer/exams/result",
      name: "AnswerExamsResult",
      meta: {
        title: "考试成绩",
        auth: true,
      },
      component: () => import("../views/answer/exam/result.vue"),
    },
    {
      path: "/answer/mocks",
      name: "AnswerMocks",
      meta: {
        title: "模拟考试",
        auth: true,
        keepAlive: true,
        useCache:false
      },
      component: () => import("../views/answer/mock/index.vue"),
    },
    {
      path: "/answer/mocks/detail",
      name: "AnswerMocksDetail",
      meta: {
        title: "考试详情",
        auth: true,
      },
      component: () => import("../views/answer/mock/detail.vue"),
    },
    {
      path: "/answer/mocks/play",
      name: "AnswerMocksPlay",
      meta: {
        auth: true,
      },
      component: () => import("../views/answer/mock/play.vue"),
    },
    {
      path: "/answer/mocks/result",
      name: "AnswerMocksResult",
      meta: {
        title: "考试成绩",
        auth: true,
      },
      component: () => import("../views/answer/mock/result.vue"),
    },
    {
      path: "/answer/learns",
      name: "AnswerLearns",
      meta: {
        title: "练习模式",
        auth: true,
        keepAlive: true,
        useCache:false
      },
      component: () => import("../views/answer/learn/index.vue"),
    },
    {
      path: "/answer/learns/detail",
      name: "AnswerLearnsDetail",
      meta: {
        title: "练习详情",
        auth: true,
      },
      component: () => import("../views/answer/learn/detail.vue"),
    },
    {
      path: "/answer/learns/play",
      name: "AnswerLearnsPlay",
      meta: {
        auth: true,
      },
      component: () => import("../views/answer/learn/play.vue"),
    },
    {
      path: "/answer/errors",
      name: "AnswerErrors",
      meta: {
        title: "错题本",
        auth: true,
      },
      component: () => import("../views/answer/error/index.vue"),
    },
    {
      path: "/answer/errors/play",
      name: "AnswerErrorsPlay",
      meta: {
        title: "错题练习",
        auth: true,
      },
      component: () => import("../views/answer/error/play.vue"),
    },
    {
      path: "/answer/collects",
      name: "AnswerCollects",
      meta: {
        title: "收藏集",
        auth: true,
      },
      component: () => import("../views/answer/collect/index.vue"),
    },
    {
      path: "/answer/collects/play",
      name: "AnswerCollectsPlay",
      meta: {
        title: "收藏题练习",
        auth: true,
      },
      component: () => import("../views/answer/collect/play.vue"),
    },
    {
      path: "/announcement/index",
      name: "Announcement",
      meta: {
        title: "公告详情",
      },
      component: () => import("../views/announcement/index.vue"),
    },
    {
      path: "/answer/search",
      name: "AnswerSearch",
      meta: {
        title: "搜索题目",
        auth: true,
        keepAlive: true,
        useCache:false
      },
      component: () => import("../views/answer/search/index.vue"),
    },
    {
      path: "/activity/index",
      name: "SignUp",
      meta: {
        title: "活动报名",
        auth: true,
        keepAlive: true,
        useCache:false
      },
      component: () => import("../views/activity/index.vue"),
    },
    {
      path: "/activity/detail",
      name: "ActDetail",
      meta: {
        title: "活动详情",
        auth: true,
        keepAlive: false,
        useCache:false
      },
      component: () => import("../views/activity/detail.vue"),
    },
    {
      path: "/activity/signin",
      name: "SignIn",
      meta: {
        title: "活动报名",
        auth: true,
        keepAlive: false,
        useCache:false
      },
      component: () => import("../views/activity/siginin.vue"),
    },
    {
      path: "/answer/search/play",
      name: "AnswerSearchPlay",
      meta: {
        title: "查看题目",
        auth: true,
        useCache:false
      },
      component: () => import("../views/answer/search/play.vue"),
    },
];

const router = new VueRouter({
    routes,
});
export default router;