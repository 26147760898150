/**
 *  (c) 智汇考题 
 */

import Vue from 'vue'
import Vuex from 'vuex'
import Utils from '@/js/utils';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
    user: null,
    config: null,
    func: {
      paper: true,
      practice: true,
      mockPaper: true,
      wrongBook: true,
      topic: true,
    },


  },
  mutations: {
    submitLogin(state, user) {
      state.user = user;
      state.isLogin = true;
    },
    setConfig(state, config) {
      state.config = config;
    },
    logout(state) {
      state.user = null;
      state.isLogin = false;
      Utils.clearToken();
    },
  },
  actions: {
  },
  modules: {
  }
})
